// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Card_card__cvxX7{
    box-shadow: inset 4px 4px 8px rgb(212, 212, 212);
    display: flex;
    width: 40%;
    justify-content: center;
    border-radius: 1rem;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding-top: 0.75rem;
    padding-bottom: 0.75rem}@media (min-width: 640px){.Card_card__cvxX7{
        width: 20%}}@media (min-width: 768px){.Card_card__cvxX7{
        width: 100%}}

.Card_qusetionText__ZLMO0{
    text-align: center}

.Card_completedText__OX81d{
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))}

.Card_completedCard__RmmA8{
    display: flex;
    width: 40%;
    justify-content: center;
    border-radius: 1rem;
    --tw-bg-opacity: 1;
    background-color: rgb(11 157 100 / var(--tw-bg-opacity));
    padding-top: 0.75rem;
    padding-bottom: 0.75rem}

@media (min-width: 640px){.Card_completedCard__RmmA8{
        width: 20%}}

@media (min-width: 768px){.Card_completedCard__RmmA8{
        width: 100%}}

.Card_nextCard__JzqoG{
    display: flex;
    width: 40%;
    justify-content: center;
    border-radius: 1rem;
    --tw-bg-opacity: 1;
    background-color: rgb(191 155 92 / var(--tw-bg-opacity));
    padding-top: 0.75rem;
    padding-bottom: 0.75rem}

@media (min-width: 640px){.Card_nextCard__JzqoG{
        width: 20%}}

@media (min-width: 768px){.Card_nextCard__JzqoG{
        width: 100%}}`, "",{"version":3,"sources":["webpack://./src/components/questions-answers/cards/Card.module.css"],"names":[],"mappings":"AAAA;IACI,gDAAgD;IAChD,aAAiF;IAAjF,UAAiF;IAAjF,uBAAiF;IAAjF,mBAAiF;IAAjF,kBAAiF;IAAjF,yDAAiF;IAAjF,oBAAiF;IAAjF,uBACJ,CADI,0BAAA;QAAA,UAAiF,CAAA,CAAjF,0BAAA;QAAA,WAAiF,CAAA;;AAIjF;IAAA,kBAAiB;;AAIjB;IAAA,oBAAgB;IAAhB,gDAAgB;;AAIhB;IAAA,aAAqF;IAArF,UAAqF;IAArF,uBAAqF;IAArF,mBAAqF;IAArF,kBAAqF;IAArF,wDAAqF;IAArF,oBAAqF;IAArF,uBAAqF;;AAArF,0BAAA;QAAA,UAAqF,CAAA;;AAArF,0BAAA;QAAA,WAAqF,CAAA;;AAIrF;IAAA,aAAoF;IAApF,UAAoF;IAApF,uBAAoF;IAApF,mBAAoF;IAApF,kBAAoF;IAApF,wDAAoF;IAApF,oBAAoF;IAApF,uBAAoF;;AAApF,0BAAA;QAAA,UAAoF,CAAA;;AAApF,0BAAA;QAAA,WAAoF,CAAA","sourcesContent":[".card{\n    box-shadow: inset 4px 4px 8px rgb(212, 212, 212);\n    @apply w-[40%] sm:w-[20%] md:w-full  bg-white py-3 rounded-2xl flex justify-center \n}\n\n.qusetionText{\n    @apply text-center \n}\n\n.completedText{\n    @apply text-white\n}\n\n.completedCard{\n    @apply w-[40%] sm:w-[20%] md:w-full  bg-[#0B9D64] py-3 rounded-2xl flex justify-center \n}\n\n.nextCard{\n    @apply w-[40%] sm:w-[20%] md:w-full bg-secondary py-3 rounded-2xl flex justify-center \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `Card_card__cvxX7`,
	"qusetionText": `Card_qusetionText__ZLMO0`,
	"completedText": `Card_completedText__OX81d`,
	"completedCard": `Card_completedCard__RmmA8`,
	"nextCard": `Card_nextCard__JzqoG`
};
export default ___CSS_LOADER_EXPORT___;
