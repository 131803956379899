// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/images/bg-2.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Question_section__bKOES {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: center;
  background-size: cover;
  background-position: center;
}

.Question_main_div__9OPUd {
  display: flex;
  align-items: center;
  justify-self: center;
}

.Question_question_div__00SWN {
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.Question_award_text__U735l {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0.025em;
  --tw-text-opacity: 1;
  color: rgb(191 155 92 / var(--tw-text-opacity));
}

@media (min-width: 768px) {

  .Question_award_text__U735l {
    font-size: 24px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/participant/question/Question.module.css"],"names":[],"mappings":"AAAA;EACE,yDAAgD;EAChD,aAA8D;EAA9D,aAA8D;EAA9D,WAA8D;EAA9D,uBAA8D;EAA9D,sBAA8D;EAA9D,2BAA8D;AAChE;;AAGE;EAAA,aAA8C;EAA9C,mBAA8C;EAA9C;AAA8C;;AAI9C;EAAA,gBAAsD;EAAtD,mBAAsD;EAAtD,aAAsD;EAAtD,WAAsD;EAAtD,mBAAsD;EAAtD;AAAsD;;AAItD;EAAA,oBAAqG;EAArG,uBAAqG;EAArG,kBAAqG;EAArG,kBAAqG;EAArG,gBAAqG;EAArG,mBAAqG;EAArG,uBAAqG;EAArG,oBAAqG;EAArG;AAAqG;;AAArG;;EAAA;IAAA;EAAqG;AAAA","sourcesContent":[".section {\n  background-image: url(\"/public/images/bg-2.png\");\n  @apply bg-cover bg-center h-screen w-full flex justify-center ;\n}\n\n.main_div {\n  @apply flex justify-self-center items-center  ;\n}\n\n.question_div {\n  @apply w-full flex justify-center items-center my-auto;\n}\n\n.award_text {\n  @apply font-semibold text-xl md:text-[24px] text-secondary py-3   leading-6 tracking-wide text-center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `Question_section__bKOES`,
	"main_div": `Question_main_div__9OPUd`,
	"question_div": `Question_question_div__00SWN`,
	"award_text": `Question_award_text__U735l`
};
export default ___CSS_LOADER_EXPORT___;
