// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search_mainDiv__ToBlc {

    display: flex;

    gap: 0.75rem
}

@media (max-width: 769px) {

    .search_mainDiv__ToBlc {

        gap: 0.25rem
    }
}

@media (max-width: 650px) {

    .search_mainDiv__ToBlc {

        margin-top: 1.25rem
    }
}

@media (max-width: 430px) {

    .search_mainDiv__ToBlc {

        margin-top: 1.25rem;

        gap: 0.25rem
    }
}

.search_searchInput__HIGSq {

    width: 18rem;

    border-radius: 0.375rem;

    border-width: 2px;

    --tw-border-opacity: 1;

    border-color: rgb(191 155 92 / var(--tw-border-opacity));

    padding-left: 1.25rem;

    padding-right: 1.25rem;

    padding-top: 0.5rem;

    padding-bottom: 0.5rem;

    outline: 2px solid transparent;

    outline-offset: 2px
}

@media (max-width: 650px) {

    .search_searchInput__HIGSq {

        width: 100%
    }
}

@media (max-width: 430px) {

    .search_searchInput__HIGSq {

        width: 70%
    }
}

.search_button__DuhBZ {

    border-radius: 0.5rem;

    --tw-bg-opacity: 1;

    background-color: rgb(191 155 92 / var(--tw-bg-opacity));

    padding-left: 2rem;

    padding-right: 2rem;

    padding-top: 1rem;

    padding-bottom: 1rem;

    font-family: Inter, sans-serif;

    text-transform: uppercase;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.search_button__DuhBZ:hover {

    opacity: 0.75
}

@media (max-width: 769px) {

    .search_button__DuhBZ {

        padding-left: 0.75rem;

        padding-right: 0.75rem
    }
}

@media (max-width: 430px) {

    .search_button__DuhBZ {

        padding-left: 0.75rem;

        padding-right: 0.75rem
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/search-input/search.module.css"],"names":[],"mappings":"AACE;;IAAA,aAA0E;;IAA1E;AAA0E;;AAA1E;;IAAA;;QAAA;IAA0E;AAAA;;AAA1E;;IAAA;;QAAA;IAA0E;AAAA;;AAA1E;;IAAA;;QAAA,mBAA0E;;QAA1E;IAA0E;AAAA;;AAI1E;;IAAA,YAAsG;;IAAtG,uBAAsG;;IAAtG,iBAAsG;;IAAtG,sBAAsG;;IAAtG,wDAAsG;;IAAtG,qBAAsG;;IAAtG,sBAAsG;;IAAtG,mBAAsG;;IAAtG,sBAAsG;;IAAtG,8BAAsG;;IAAtG;AAAsG;;AAAtG;;IAAA;;QAAA;IAAsG;AAAA;;AAAtG;;IAAA;;QAAA;IAAsG;AAAA;;AAItG;;IAAA,qBAAuH;;IAAvH,kBAAuH;;IAAvH,wDAAuH;;IAAvH,kBAAuH;;IAAvH,mBAAuH;;IAAvH,iBAAuH;;IAAvH,oBAAuH;;IAAvH,8BAAuH;;IAAvH,yBAAuH;;IAAvH,oBAAuH;;IAAvH;AAAuH;;AAAvH;;IAAA;AAAuH;;AAAvH;;IAAA;;QAAA,qBAAuH;;QAAvH;IAAuH;AAAA;;AAAvH;;IAAA;;QAAA,qBAAuH;;QAAvH;IAAuH;AAAA","sourcesContent":[".mainDiv {\n  @apply flex gap-3  max-430:gap-1 max-768:gap-1  max-430:mt-5 max-650:mt-5 ;\n}\n\n.searchInput {\n  @apply outline-none px-5 py-2 w-72 max-430:w-[70%] max-650:w-full border-secondary border-2 rounded-md;\n}\n\n.button {\n  @apply bg-secondary rounded-lg font-inter  text-white max-768:px-3   max-430:px-3  px-8 py-4 hover:opacity-75 uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainDiv": `search_mainDiv__ToBlc`,
	"searchInput": `search_searchInput__HIGSq`,
	"button": `search_button__DuhBZ`
};
export default ___CSS_LOADER_EXPORT___;
