// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.userPopup_wrapper__qnbrv {

    position: fixed;

    inset: 0px;

    z-index: 50;

    display: flex;

    align-items: center;

    justify-content: center
}

.userPopup_overlay__jzpEE {

    position: absolute;

    inset: 0px;

    --tw-bg-opacity: 1;

    background-color: rgb(0 0 0 / var(--tw-bg-opacity));

    opacity: 0.5
}

.userPopup_popup__iGROm {

    position: absolute;

    z-index: 50;

    width: 100%;

    align-items: center;

    justify-content: center;

    overflow-y: auto;

    overflow-x: hidden;

    border-radius: 1.5rem;

    --tw-border-opacity: 1;

    border-color: rgb(233 234 240 / var(--tw-border-opacity));

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity));

    padding: 1.25rem
}

@media (min-width: 768px) {

    .userPopup_popup__iGROm {

        width: 75vh
    }
}

.userPopup_header__xFev9 {

    display: flex;

    width: 100%;

    align-items: center;

    justify-content: center
}

.userPopup_photoContainer__DoNe4 {

    width: 100%
}

.userPopup_photoWrapper__tKrMg {

    padding-top: 0.75rem;

    padding-bottom: 0.75rem
}

.userPopup_photo__0O1RC {

    margin-left: auto;

    margin-right: auto;

    height: 8rem;

    width: 8rem;

    border-radius: 9999px
}

.userPopup_nameText__y\\+cSs {

    text-align: center;

    font-family: Noto Sans, sans-serif;

    font-size: 2.25rem;

    line-height: 2.5rem;

    font-weight: 600;

    line-height: 49.03px
}
.userPopup_closeButtonImage__xUFLs {

    height: 1.5rem;

    cursor: pointer
}

.userPopup_closeImageContainer__0CzL- {

    position: absolute;

    right: 1rem;

    top: 1rem
}


.userPopup_button__aV2sM {

    border-radius: 0.5rem;

    --tw-bg-opacity: 1;

    background-color: rgb(191 155 92 / var(--tw-bg-opacity));

    padding-left: 2rem;

    padding-right: 2rem;

    padding-top: 1rem;

    padding-bottom: 1rem;

    font-family: Inter, sans-serif;

    text-transform: uppercase;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}


.userPopup_button__aV2sM:hover {

    opacity: 0.75
}
  `, "",{"version":3,"sources":["webpack://./src/components/home/user-card/user-popup/userPopup.module.css"],"names":[],"mappings":"AACE;;IAAA,eAA0D;;IAA1D,UAA0D;;IAA1D,WAA0D;;IAA1D,aAA0D;;IAA1D,mBAA0D;;IAA1D;AAA0D;;AAI1D;;IAAA,kBAA2C;;IAA3C,UAA2C;;IAA3C,kBAA2C;;IAA3C,mDAA2C;;IAA3C;AAA2C;;AAI3C;;IAAA,kBAA8I;;IAA9I,WAA8I;;IAA9I,WAA8I;;IAA9I,mBAA8I;;IAA9I,uBAA8I;;IAA9I,gBAA8I;;IAA9I,kBAA8I;;IAA9I,qBAA8I;;IAA9I,sBAA8I;;IAA9I,yDAA8I;;IAA9I,kBAA8I;;IAA9I,yDAA8I;;IAA9I;AAA8I;;AAA9I;;IAAA;;QAAA;IAA8I;AAAA;;AAI9I;;IAAA,aAA8C;;IAA9C,WAA8C;;IAA9C,mBAA8C;;IAA9C;AAA8C;;AAI9C;;IAAA;AAAa;;AAIb;;IAAA,oBAAY;;IAAZ;AAAY;;AAIZ;;IAAA,iBAAqC;;IAArC,kBAAqC;;IAArC,YAAqC;;IAArC,WAAqC;;IAArC;AAAqC;;AAIrC;;IAAA,kBAAqE;;IAArE,kCAAqE;;IAArE,kBAAqE;;IAArE,mBAAqE;;IAArE,gBAAqE;;IAArE;AAAqE;AAGnE;;IAAA,cAA2B;;IAA3B;AAA2B;;AAI3B;;IAAA,kBAA6B;;IAA7B,WAA6B;;IAA7B;AAA6B;;;AAK7B;;IAAA,qBAA0F;;IAA1F,kBAA0F;;IAA1F,wDAA0F;;IAA1F,kBAA0F;;IAA1F,mBAA0F;;IAA1F,iBAA0F;;IAA1F,oBAA0F;;IAA1F,8BAA0F;;IAA1F,yBAA0F;;IAA1F,oBAA0F;;IAA1F;AAA0F;;;AAA1F;;IAAA;AAA0F","sourcesContent":[".wrapper {\n  @apply fixed inset-0 z-50 flex items-center justify-center;\n}\n\n.overlay {\n  @apply absolute inset-0 bg-black opacity-50;\n}\n\n.popup {\n  @apply absolute md:w-[75vh] border-primary rounded-3xl p-5 overflow-y-auto overflow-x-hidden bg-white  z-50 justify-center items-center w-full;\n}\n\n.header {\n  @apply flex items-center w-full justify-center;\n}\n\n.photoContainer {\n  @apply w-full;\n}\n\n.photoWrapper {\n  @apply py-3 ;\n}\n\n.photo {\n  @apply w-32 h-32 rounded-full mx-auto;\n}\n\n.nameText {\n  @apply font-noto text-4xl font-semibold leading-[49.03px] text-center;\n}\n.closeButtonImage{\n    @apply h-6  cursor-pointer ;\n}\n\n.closeImageContainer{\n    @apply absolute right-4 top-4;\n}\n\n\n.button {\n    @apply bg-secondary rounded-lg font-inter  text-white px-8 py-4 hover:opacity-75 uppercase;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `userPopup_wrapper__qnbrv`,
	"overlay": `userPopup_overlay__jzpEE`,
	"popup": `userPopup_popup__iGROm`,
	"header": `userPopup_header__xFev9`,
	"photoContainer": `userPopup_photoContainer__DoNe4`,
	"photoWrapper": `userPopup_photoWrapper__tKrMg`,
	"photo": `userPopup_photo__0O1RC`,
	"nameText": `userPopup_nameText__y+cSs`,
	"closeButtonImage": `userPopup_closeButtonImage__xUFLs`,
	"closeImageContainer": `userPopup_closeImageContainer__0CzL-`,
	"button": `userPopup_button__aV2sM`
};
export default ___CSS_LOADER_EXPORT___;
