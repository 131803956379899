// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QuestionCard_card__obA6l{
    box-shadow: inset 4px 4px 8px rgb(212, 212, 212);
    display: flex;
    width: 100%;
    justify-content: flex-start;
    border-radius: 1rem;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem}

.QuestionCard_qusetionText__EvLa1{
    text-align: center}

.QuestionCard_completedText__6TqGp{
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))}

.QuestionCard_completedCard__uiEuG{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    border-radius: 1rem;
    --tw-bg-opacity: 1;
    background-color: rgb(11 157 100 / var(--tw-bg-opacity));
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem}

.QuestionCard_nextCard__7Gco1{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    border-radius: 1rem;
    --tw-bg-opacity: 1;
    background-color: rgb(191 155 92 / var(--tw-bg-opacity));
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem}`, "",{"version":3,"sources":["webpack://./src/components/qestion-cards/QuestionCard.module.css"],"names":[],"mappings":"AAAA;IACI,gDAAgD;IAChD,aAA8D;IAA9D,WAA8D;IAA9D,2BAA8D;IAA9D,mBAA8D;IAA9D,kBAA8D;IAA9D,yDAA8D;IAA9D,oBAA8D;IAA9D,uBAA8D;IAA9D,qBAA8D;IAA9D,sBACJ;;AAGI;IAAA,kBAAiB;;AAIjB;IAAA,oBAAgB;IAAhB,gDAAgB;;AAIhB;IAAA,aAAkE;IAAlE,WAAkE;IAAlE,2BAAkE;IAAlE,mBAAkE;IAAlE,kBAAkE;IAAlE,wDAAkE;IAAlE,oBAAkE;IAAlE,uBAAkE;IAAlE,qBAAkE;IAAlE,sBAAkE;;AAIlE;IAAA,aAAkE;IAAlE,WAAkE;IAAlE,2BAAkE;IAAlE,mBAAkE;IAAlE,kBAAkE;IAAlE,wDAAkE;IAAlE,oBAAkE;IAAlE,uBAAkE;IAAlE,qBAAkE;IAAlE,sBAAkE","sourcesContent":[".card{\n    box-shadow: inset 4px 4px 8px rgb(212, 212, 212);\n    @apply w-full bg-white py-3 rounded-2xl flex justify-start px-5\n}\n\n.qusetionText{\n    @apply text-center \n}\n\n.completedText{\n    @apply text-white\n}\n\n.completedCard{\n    @apply w-full bg-[#0B9D64] py-3 rounded-2xl flex justify-start px-5\n}\n\n.nextCard{\n    @apply w-full bg-secondary py-3 rounded-2xl flex justify-start px-5\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `QuestionCard_card__obA6l`,
	"qusetionText": `QuestionCard_qusetionText__EvLa1`,
	"completedText": `QuestionCard_completedText__6TqGp`,
	"completedCard": `QuestionCard_completedCard__uiEuG`,
	"nextCard": `QuestionCard_nextCard__7Gco1`
};
export default ___CSS_LOADER_EXPORT___;
