// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navBar_navBarContainer__luaXs {
    margin-left: auto;
    margin-right: auto;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    --tw-bg-opacity: 1;
    background-color: rgb(233 234 240 / var(--tw-bg-opacity));
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.navBar_navBarContent__eTT8b {
    display: flex;
    justify-content: space-between;
}

.navBar_logoContainer__-hDaF {
    display: flex;
    flex: 1 1;
    align-items: center;
}

@media (min-width: 640px) {

    .navBar_logoContainer__-hDaF {
        justify-content: flex-start;
    }
}

.navBar_logoImage__oUOpz {
    height: 6rem;
    width: auto;
    cursor: pointer;
    padding: 0.25rem;
    padding-left: 0px;
}

.navBar_userProfileContainer__H2jV9 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.75rem;
    padding-right: 0.75rem;
}

.navBar_userProfileImage__LCMtW {
    height: 3rem;
    width: 3rem;
    border-radius: 9999px;
}

@media (max-width: 430px) {

    .navBar_userProfileImage__LCMtW {
        height: 2.5rem;
        width: 2.5rem;
    }
}

.navBar_logoutImage__803Qa {
    cursor: pointer;
    height: 1.75rem;
}

@media (max-width: 430px) {

    .navBar_logoutImage__803Qa {
        height: 1.5rem;
        width: 1.5rem;
    }
}
.navBar_nameText__oQou0 {
  letter-spacing: 0.38px;
  font-family: Alexandria, sans-serif;
  font-size: 14px;
  font-weight: 550;
  line-height: 18px;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
@media (max-width: 430px) {

    .navBar_nameText__oQou0 {
        font-size: 12px;
    }
}

.navBar_locationText__RMwp\\+ {
  letter-spacing: 0.38px;
  display: flex;
  gap: 0.25rem;
  font-family: Alexandria, sans-serif;
  font-size: 15px;
  font-weight: 550;
  line-height: 18px;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

@media (max-width: 430px) {

    .navBar_locationText__RMwp\\+ {
        font-size: 12px;
    }
}
.navBar_locationIcon__j6FnJ {
    height: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/navBar/navBar.module.css"],"names":[],"mappings":"AACE;IAAA,iBAAyD;IAAzD,kBAAyD;IAAzD,gCAAyD;IAAzD,+BAAyD;IAAzD,kBAAyD;IAAzD,yDAAyD;IAAzD,oBAAyD;IAAzD,qBAAyD;IAAzD,+EAAyD;IAAzD,mGAAyD;IAAzD;AAAyD;;AAIzD;IAAA,aAA4B;IAA5B;AAA4B;;AAI5B;IAAA,aAAgD;IAAhD,SAAgD;IAAhD;AAAgD;;AAAhD;;IAAA;QAAA;IAAgD;AAAA;;AAIhD;IAAA,YAA0C;IAA1C,WAA0C;IAA1C,eAA0C;IAA1C,gBAA0C;IAA1C;AAA0C;;AAI1C;IAAA,aAAoD;IAApD,mBAAoD;IAApD,8BAAoD;IAApD,YAAoD;IAApD;AAAoD;;AAIpD;IAAA,YAAuD;IAAvD,WAAuD;IAAvD;AAAuD;;AAAvD;;IAAA;QAAA,cAAuD;QAAvD;IAAuD;AAAA;;AAGzD;IACI,eAAe;IACjB,eAAkC;AACpC;;AADE;;IAAA;QAAA,cAAkC;QAAlC;IAAkC;AAAA;AAEpC;EACE,sBAAsB;EACtB,mCAMc;EANd,eAMc;EANd,gBAMc;EANd,iBAMc;EANd,oBAMc;EANd,0CAMc;AAChB;AAPE;;IAAA;QAAA;IAMc;AAAA;;AAGhB;EACE,sBAAsB;EACtB,aAOgB;EAPhB,YAOgB;EAPhB,mCAOgB;EAPhB,eAOgB;EAPhB,gBAOgB;EAPhB,iBAOgB;EAPhB,oBAOgB;EAPhB,0CAOgB;AAClB;;AARE;;IAAA;QAAA;IAOgB;AAAA;AAGhB;IAAA;AAAU","sourcesContent":[".navBarContainer {\n  @apply bg-primary mx-auto px-2 rounded-b-[30px] shadow-lg;\n}\n\n.navBarContent {\n  @apply flex  justify-between;\n}\n\n.logoContainer {\n  @apply flex flex-1 sm:justify-start items-center;\n}\n\n.logoImage {\n  @apply h-24 p-1 pl-0 w-auto cursor-pointer;\n}\n\n.userProfileContainer {\n  @apply flex justify-between items-center gap-3  pr-3;\n}\n\n.userProfileImage {\n  @apply h-12 w-12 max-430:h-10 max-430:w-10 rounded-full;\n}\n\n.logoutImage {\n    cursor: pointer;\n  @apply h-7 max-430:h-6 max-430:w-6;\n}\n.nameText {\n  letter-spacing: 0.38px;\n  @apply\n  text-[14px]\n  max-430:text-[12px]\n  font-primary\n  font-[550]\n  text-black\n  leading-[18px];\n}\n\n.locationText {\n  letter-spacing: 0.38px;\n  @apply flex\n    gap-1\n    text-[15px]\n    max-430:text-[12px]\n    font-[550]\n    font-primary\n    text-black\n    leading-[18px];\n}\n.locationIcon {\n  @apply h-4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navBarContainer": `navBar_navBarContainer__luaXs`,
	"navBarContent": `navBar_navBarContent__eTT8b`,
	"logoContainer": `navBar_logoContainer__-hDaF`,
	"logoImage": `navBar_logoImage__oUOpz`,
	"userProfileContainer": `navBar_userProfileContainer__H2jV9`,
	"userProfileImage": `navBar_userProfileImage__LCMtW`,
	"logoutImage": `navBar_logoutImage__803Qa`,
	"nameText": `navBar_nameText__oQou0`,
	"locationText": `navBar_locationText__RMwp+`,
	"locationIcon": `navBar_locationIcon__j6FnJ`
};
export default ___CSS_LOADER_EXPORT___;
