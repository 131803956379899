// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .next_button_div{
    @apply flex justify-end;
} */

.NextButton_next_button__bdZyR {

    margin-top: 2.75rem;

    margin-bottom: 2.75rem;

    max-height: 3.5rem;

    border-radius: 0.5rem;

    --tw-bg-opacity: 1;

    background-color: rgb(11 157 100 / var(--tw-bg-opacity));

    padding-top: 0.5rem;

    padding-bottom: 0.5rem;

    padding-left: 1.25rem;

    padding-right: 1.25rem;

    font-size: 0.875rem;

    line-height: 1.25rem;

    font-weight: 500;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

@media (min-width: 768px) {

    .NextButton_next_button__bdZyR {

        margin-top: 1.25rem;

        margin-bottom: 1.25rem;

        padding-top: 1.25rem;

        padding-bottom: 1.25rem;

        padding-left: 4rem;

        padding-right: 4rem
    }
}

@media (min-width: 1024px) {

    .NextButton_next_button__bdZyR {

        font-size: 20px
    }
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/next-button/NextButton.module.css"],"names":[],"mappings":"AAAA;;GAEG;;AAGC;;IAAA,mBAA8H;;IAA9H,sBAA8H;;IAA9H,kBAA8H;;IAA9H,qBAA8H;;IAA9H,kBAA8H;;IAA9H,wDAA8H;;IAA9H,mBAA8H;;IAA9H,sBAA8H;;IAA9H,qBAA8H;;IAA9H,sBAA8H;;IAA9H,mBAA8H;;IAA9H,oBAA8H;;IAA9H,gBAA8H;;IAA9H,oBAA8H;;IAA9H;AAA8H;;AAA9H;;IAAA;;QAAA,mBAA8H;;QAA9H,sBAA8H;;QAA9H,oBAA8H;;QAA9H,uBAA8H;;QAA9H,kBAA8H;;QAA9H;IAA8H;AAAA;;AAA9H;;IAAA;;QAAA;IAA8H;AAAA","sourcesContent":["/* .next_button_div{\n    @apply flex justify-end;\n} */\n\n.next_button{\n    @apply py-2 md:py-5 px-5 md:px-16 max-h-14  text-white font-medium bg-[#0B9D64] my-11 md:my-5 rounded-lg text-sm lg:text-[20px]\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"next_button": `NextButton_next_button__bdZyR`
};
export default ___CSS_LOADER_EXPORT___;
