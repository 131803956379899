// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/images/bg-3.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home_section__DT88h {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.home_container__OAsEn {
  margin-bottom: 1.25rem;
  padding-left: 7rem;
  padding-right: 7rem;
  padding-top: 2.5rem;
}

@media (max-width: 900px) {

  .home_container__OAsEn {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

@media (max-width: 769px) {

  .home_container__OAsEn {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

@media (max-width: 430px) {

  .home_container__OAsEn {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

.home_header__p4sTO {
  display: flex;
  justify-content: space-between;
}

.home_WelcomeText__v\\+ryl {
  display: flex;
  align-items: center;
  gap: 1rem;
  text-align: left;
  font-family: Amiri-Quran;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.38px;
}

@media (max-width: 430px) {

  .home_WelcomeText__v\\+ryl {
    gap: 0.25rem;
    font-size: 20px;
  }
}

.home_nameText__xBKIK {
  font-family: Amiri-Quran;
  font-size: 30px;
  font-weight: 400;
  line-height: 24px;
}

@media (max-width: 430px) {

  .home_nameText__xBKIK {
    margin-left: 0px;
    text-align: center;
  }
}

.home_locationImage__JGB71 {
  height: 2.5rem;
}

@media (max-width: 430px) {

  .home_locationImage__JGB71 {
    height: 1.5rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/judge/home/home.module.css"],"names":[],"mappings":"AAAA;EACE,yDAAgD;EAChD,aAAkC;EAAlC,sBAAkC;EAAlC,2BAAkC;AACpC;;AAGE;EAAA,sBAAiE;EAAjE,kBAAiE;EAAjE,mBAAiE;EAAjE;AAAiE;;AAAjE;;EAAA;IAAA,oBAAiE;IAAjE;EAAiE;AAAA;;AAAjE;;EAAA;IAAA,oBAAiE;IAAjE;EAAiE;AAAA;;AAAjE;;EAAA;IAAA,qBAAiE;IAAjE;EAAiE;AAAA;;AAIjE;EAAA,aAA2B;EAA3B;AAA2B;;AAI3B;EAAA,aAUO;EAVP,mBAUO;EAVP,SAUO;EAVP,gBAUO;EAVP,wBAUO;EAVP,iBAUO;EAVP,iBAUO;EAVP,gBAUO;EAVP,iBAUO;EAVP;AAUO;;AAVP;;EAAA;IAAA,YAUO;IAVP;EAUO;AAAA;;AAIP;EAAA,wBAOe;EAPf,eAOe;EAPf,gBAOe;EAPf;AAOe;;AAPf;;EAAA;IAAA,gBAOe;IAPf;EAOe;AAAA;;AAIf;EAAA;AAAuB;;AAAvB;;EAAA;IAAA;EAAuB;AAAA","sourcesContent":[".section {\n  background-image: url(\"/public/images/bg-3.png\");\n  @apply bg-cover bg-center h-screen;\n}\n\n.container {\n  @apply max-430:px-5 max-768:px-10  max-900:px-10 px-28 pt-10 mb-5;\n}\n\n.header {\n  @apply flex justify-between;\n}\n\n.WelcomeText {\n  @apply \n  max-430:text-[20px]\n  max-430:gap-1\n  flex \n  font-[Amiri-Quran] \n  text-2xl font-normal \n  leading-[24px]\n   tracking-[0.38px] \n   text-left\n    items-center \n    gap-4;\n}\n\n.nameText {\n  @apply\n   \n   max-430:ml-0\n  font-[Amiri-Quran] \n   text-[30px] \n   max-430:text-center\n   font-normal \n   leading-[24px];\n}\n\n.locationImage {\n  @apply h-10 max-430:h-6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `home_section__DT88h`,
	"container": `home_container__OAsEn`,
	"header": `home_header__p4sTO`,
	"WelcomeText": `home_WelcomeText__v+ryl`,
	"nameText": `home_nameText__xBKIK`,
	"locationImage": `home_locationImage__JGB71`
};
export default ___CSS_LOADER_EXPORT___;
