// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/images/bg-2.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QuestionsList_section__r0SK6 {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    width: 100%;
    background-size: cover;
    background-position: center;
  }
  
  .QuestionsList_container__IJJJz {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-top: 1.25rem;
}
  
  @media (min-width: 768px) {
  
    .QuestionsList_container__IJJJz {
        margin-top: 1.25rem;
    }
}
  
  .QuestionsList_header__a9SWC {
    display: flex;
    justify-content: flex-end;
}
  
  .QuestionsList_WelcomeText__O-gpG {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    font-family: Amiri-Quran;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.38px;
}
  
  @media (min-width: 1024px) {
  
    .QuestionsList_WelcomeText__O-gpG {
        font-size: 1.5rem;
        line-height: 2rem;
    }
}
  
  /* .nameText {
    @apply mt-3
     ml-10
    font-[Amiri-Quran] 
     text-[36px] 
     font-normal 
     leading-[24px];
  } */
  
  .QuestionsList_locationImage__ZBF2j {
    height: 1.25rem;
}
  
  @media (min-width: 768px) {
  
    .QuestionsList_locationImage__ZBF2j {
        height: 2.5rem;
    }
}
  .QuestionsList_currentparticipant__SBwxV {
    display: flex;
    align-items: center;
    justify-content: center;
}
  

  .QuestionsList_nameText__jphOQ {
    font-family: Inter, sans-serif;
    font-size: 20px;
    font-weight: 700;
    --tw-text-opacity: 1;
    color: rgb(11 160 102 / var(--tw-text-opacity));
}
  

  @media (min-width: 768px) {
  
    .QuestionsList_nameText__jphOQ {
        font-size: 41px;
    }
}


  .QuestionsList_userDetailes__0hzTa {
    flex-direction: column;
    justify-content: flex-end;
}

  .QuestionsList_main_title__xkQ-j {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(191 155 92 / var(--tw-text-opacity));
}

  @media (min-width: 768px) {
  
    .QuestionsList_main_title__xkQ-j {
        font-size: 36px;
    }
}

  `, "",{"version":3,"sources":["webpack://./src/pages/judge/questions-list/QuestionsList.module.css"],"names":[],"mappings":"AAAA;IACI,yDAAgD;IAChD,WAAiC;IAAjC,sBAAiC;IAAjC,2BAAiC;EACnC;;EAGE;IAAA,oBAA2B;IAA3B,qBAA2B;IAA3B;AAA2B;;EAA3B;;IAAA;QAAA;IAA2B;AAAA;;EAI3B;IAAA,aAAuB;IAAvB;AAAuB;;EAIvB;IAAA,aAOoB;IAPpB,mBAOoB;IAPpB,yBAOoB;IAPpB,SAOoB;IAPpB,wBAOoB;IAPpB,eAOoB;IAPpB,gBAOoB;IAPpB,iBAOoB;IAPpB;AAOoB;;EAPpB;;IAAA;QAAA,iBAOoB;QAPpB;IAOoB;AAAA;;EAGtB;;;;;;;KAOG;;EAGD;IAAA;AAAkB;;EAAlB;;IAAA;QAAA;IAAkB;AAAA;EAGlB;IAAA,aAAuC;IAAvC,mBAAuC;IAAvC;AAAuC;;;EAKtC;IAAA,8BAAmE;IAAnE,eAAmE;IAAnE,gBAAmE;IAAnE,oBAAmE;IAAnE;AAAmE;;;EAAnE;;IAAA;QAAA;IAAmE;AAAA;;;EAKpE;IAAA,sBAA0B;IAA1B;AAA0B;;EAIzB;IAAA,kBAAuE;IAAvE,eAAuE;IAAvE,gBAAuE;IAAvE,oBAAuE;IAAvE;AAAuE;;EAAvE;;IAAA;QAAA;IAAuE;AAAA","sourcesContent":[".section {\n    background-image: url(\"/public/images/bg-2.png\");\n    @apply bg-cover bg-center w-full ;\n  }\n  \n  .container {\n    @apply  mx-5 mt-5   md:mt-5;\n  }\n  \n  .header {\n    @apply flex justify-end;\n  }\n  \n  .WelcomeText {\n    @apply flex \n    justify-end\n    font-[Amiri-Quran] \n    text-[12px]\n    lg:text-2xl font-normal \n    leading-[24px]\n     tracking-[0.38px] \n      items-center gap-4;\n  }\n  \n  /* .nameText {\n    @apply mt-3\n     ml-10\n    font-[Amiri-Quran] \n     text-[36px] \n     font-normal \n     leading-[24px];\n  } */\n  \n  .locationImage {\n    @apply h-5 md:h-10;\n  }\n  .currentparticipant{\n    @apply flex  justify-center items-center\n  }\n  \n\n  .nameText{\n     @apply text-[20px] md:text-[41px] font-bold text-[#0BA066] font-sans\n  }\n\n\n  .userDetailes{\n    @apply flex-col justify-end\n  }\n\n  .main_title{\n     @apply text-[20px] md:text-[36px] font-medium text-center text-secondary \n  }\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `QuestionsList_section__r0SK6`,
	"container": `QuestionsList_container__IJJJz`,
	"header": `QuestionsList_header__a9SWC`,
	"WelcomeText": `QuestionsList_WelcomeText__O-gpG`,
	"locationImage": `QuestionsList_locationImage__ZBF2j`,
	"currentparticipant": `QuestionsList_currentparticipant__SBwxV`,
	"nameText": `QuestionsList_nameText__jphOQ`,
	"userDetailes": `QuestionsList_userDetailes__0hzTa`,
	"main_title": `QuestionsList_main_title__xkQ-j`
};
export default ___CSS_LOADER_EXPORT___;
