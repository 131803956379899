// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.users_mainContainer__bxzR5 {
    margin-top: 1.75rem;
    height: 100%;
    width: 100%;
    border-radius: 25px;
    --tw-bg-opacity: 1;
    background-color: rgb(233 234 240 / var(--tw-bg-opacity));
    padding: 1.75rem;
    padding-left: 5rem;
    padding-right: 5rem;
    --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}
@media (max-width: 900px) {
    .users_mainContainer__bxzR5 {
        padding-left: 1.75rem;
        padding-right: 1.75rem
    }
}
@media (max-width: 769px) {
    .users_mainContainer__bxzR5 {
        padding-left: 1.75rem;
        padding-right: 1.75rem
    }
}
@media (max-width: 430px) {
    .users_mainContainer__bxzR5 {
        padding-left: 1.25rem;
        padding-right: 1.25rem
    }
}
.users_headContainer__xV4MG {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between
}
@media (max-width: 650px) {
    .users_headContainer__xV4MG {
        display: block
    }
}
@media (max-width: 430px) {
    .users_headContainer__xV4MG {
        display: block
    }
}
.users_headText__jD9zw {
    font-family: Amiri-Quran;
    font-size: 32px;
    font-weight: 400;
    line-height: 24px
}
@media (max-width: 769px) {
    .users_headText__jD9zw {
        font-size: 28px
    }
}
@media (max-width: 650px) {
    .users_headText__jD9zw {
        text-align: center
    }
}
@media (max-width: 430px) {
    .users_headText__jD9zw {
        text-align: center;
        font-size: 27px
    }
}

.users_userContainer__D6hbn {
    margin-top: 2.5rem;
    display: grid;
    column-gap: 2.5rem;
    row-gap: 2rem
}

@media (min-width: 768px) {
    .users_userContainer__D6hbn {
        grid-template-columns: repeat(4, minmax(0, 1fr))
    }
}

@media (max-width: 1030px) {
    .users_userContainer__D6hbn {
        grid-template-columns: repeat(3, minmax(0, 1fr))
    }
}

@media (max-width: 769px) {
    .users_userContainer__D6hbn {
        grid-template-columns: repeat(3, minmax(0, 1fr))
    }
}

@media (max-width: 650px) {
    .users_userContainer__D6hbn {
        grid-template-columns: repeat(2, minmax(0, 1fr))
    }
}

@media (max-width: 430px) {
    .users_userContainer__D6hbn {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        row-gap: 1.25rem
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/home/users/users.module.css"],"names":[],"mappings":"AACE;IAAA,mBAMkD;IANlD,YAMkD;IANlD,WAMkD;IANlD,mBAMkD;IANlD,kBAMkD;IANlD,yDAMkD;IANlD,gBAMkD;IANlD,kBAMkD;IANlD,mBAMkD;IANlD,gDAMkD;IANlD,6DAMkD;IANlD;AAMkD;AANlD;IAAA;QAAA,qBAMkD;QANlD;IAMkD;AAAA;AANlD;IAAA;QAAA,qBAMkD;QANlD;IAMkD;AAAA;AANlD;IAAA;QAAA,qBAMkD;QANlD;IAMkD;AAAA;AAGlD;IAAA,aAA6E;IAA7E,WAA6E;IAA7E,mBAA6E;IAA7E;AAA6E;AAA7E;IAAA;QAAA;IAA6E;AAAA;AAA7E;IAAA;QAAA;IAA6E;AAAA;AAG7E;IAAA,wBAOgB;IAPhB,eAOgB;IAPhB,gBAOgB;IAPhB;AAOgB;AAPhB;IAAA;QAAA;IAOgB;AAAA;AAPhB;IAAA;QAAA;IAOgB;AAAA;AAPhB;IAAA;QAAA,kBAOgB;QAPhB;IAOgB;AAAA;;AAIhB;IAAA,kBASe;IATf,aASe;IATf,kBASe;IATf;AASe;;AATf;IAAA;QAAA;IASe;AAAA;;AATf;IAAA;QAAA;IASe;AAAA;;AATf;IAAA;QAAA;IASe;AAAA;;AATf;IAAA;QAAA;IASe;AAAA;;AATf;IAAA;QAAA,gDASe;QATf;IASe;AAAA","sourcesContent":[".mainContainer {\n  @apply bg-primary \n    w-full\n    h-full\n    mt-7\n    shadow-2xl\n    rounded-[25px]\n    p-7 px-20 max-430:px-5 max-768:px-7 max-900:px-7;\n}\n.headContainer {\n  @apply max-430:block max-650:block  flex justify-between  w-full items-center;\n}\n.headText {\n  @apply font-[Amiri-Quran] \n  max-430:text-[27px] \n  max-430:text-center\n  max-650:text-center\n  max-768:text-[28px] \n    text-[32px] \n    font-normal \n    leading-[24px];\n}\n\n.userContainer {\n  @apply mt-10 \n  grid \n  max-1024:grid-cols-3  \n  max-768:grid-cols-3 \n  max-650:grid-cols-2 \n  max-430:grid-cols-1  \n  md:grid-cols-4    \n  gap-x-10\n  gap-y-8 \n  max-430:gap-y-5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `users_mainContainer__bxzR5`,
	"headContainer": `users_headContainer__xV4MG`,
	"headText": `users_headText__jD9zw`,
	"userContainer": `users_userContainer__D6hbn`
};
export default ___CSS_LOADER_EXPORT___;
