// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.gradientbutton_submit_button__GwakC {
    width: 90%;
    background-image: linear-gradient(
      to right,
      #c09b5c 0%,
      #000000 51%,
      #c09b5c 100%
    );
    margin: 10px;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
  }
  
  .gradientbutton_submit_button__GwakC:hover {
    background-position: right center;
    color: #fff;
    text-decoration: none;
  }
  
  .gradientbutton_submit_button_div__Xue4L {
    display: flex;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/buttons/gradientbutton/gradientbutton.module.css"],"names":[],"mappings":";AACA;IACI,UAAU;IACV;;;;;KAKC;IACD,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,gBAAgB;IAChB,0BAA0B;IAC1B,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;EAChB;;EAEA;IACE,iCAAiC;IACjC,WAAW;IACX,qBAAqB;EACvB;;EAGE;IAAA,aAA0B;IAA1B;AAA0B","sourcesContent":["\n.submit_button {\n    width: 90%;\n    background-image: linear-gradient(\n      to right,\n      #c09b5c 0%,\n      #000000 51%,\n      #c09b5c 100%\n    );\n    margin: 10px;\n    padding: 10px;\n    text-align: center;\n    text-transform: uppercase;\n    transition: 0.5s;\n    background-size: 200% auto;\n    color: white;\n    box-shadow: 0 0 20px #eee;\n    border-radius: 10px;\n    display: block;\n  }\n  \n  .submit_button:hover {\n    background-position: right center;\n    color: #fff;\n    text-decoration: none;\n  }\n  \n  .submit_button_div {\n    @apply flex justify-center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"submit_button": `gradientbutton_submit_button__GwakC`,
	"submit_button_div": `gradientbutton_submit_button_div__Xue4L`
};
export default ___CSS_LOADER_EXPORT___;
