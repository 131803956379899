// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QuestionAnswerCard_main_section__97l7c{
    box-shadow: inset 4px 4px 8px rgb(137, 138, 135);
    box-shadow: 10px 10px 15px rgba(147, 160, 188, 0.7);
    width: 100%;
    border-radius: 1.5rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(191 155 92 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity));
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-top: 1.25rem;
    padding-bottom: 0.5rem;
    font-family: Alexandria, sans-serif}@media (min-width: 768px){.QuestionAnswerCard_main_section__97l7c{
        margin-left: 5rem;
        margin-right: 5rem;
        width: 90%}}
.QuestionAnswerCard_titile__X5qhk{
    text-align: start;
    font-size: 20px;
    font-weight: 500;
    --tw-text-opacity: 1;
    color: rgb(191 155 92 / var(--tw-text-opacity))}
@media (min-width: 768px){.QuestionAnswerCard_titile__X5qhk{
        font-size: 25px}}

.QuestionAnswerCard_descrption__wsW59{
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: center;
    font-size: 1.25rem;
    font-size: 25px;
    line-height: 2rem}`, "",{"version":3,"sources":["webpack://./src/components/questionanswercard/QuestionAnswerCard.module.css"],"names":[],"mappings":"AAAA;IACI,gDAAgD;IAChD,mDAAmD;IACnD,WAAuH;IAAvH,qBAAuH;IAAvH,iBAAuH;IAAvH,sBAAuH;IAAvH,wDAAuH;IAAvH,kBAAuH;IAAvH,yDAAuH;IAAvH,oBAAuH;IAAvH,qBAAuH;IAAvH,oBAAuH;IAAvH,sBAAuH;IAAvH,mCACJ,CADI,0BAAA;QAAA,iBAAuH;QAAvH,kBAAuH;QAAvH,UAAuH,CAAA;AAGvH;IAAA,iBAAsE;IAAtE,eAAsE;IAAtE,gBAAsE;IAAtE,oBAAsE;IAAtE,+CAAsE;AAAtE,0BAAA;QAAA,eAAsE,CAAA;;AAItE;IAAA,kBAAqD;IAArD,qBAAqD;IAArD,kBAAqD;IAArD,kBAAqD;IAArD,eAAqD;IAArD,iBAAqD","sourcesContent":[".main_section{\n    box-shadow: inset 4px 4px 8px rgb(137, 138, 135);\n    box-shadow: 10px 10px 15px rgba(147, 160, 188, 0.7);\n    @apply w-full md:w-[90%] rounded-3xl border-[1px] border-secondary  md:mx-20 px-10 pt-5 pb-2  bg-slate-300  font-primary\n}\n.titile{\n    @apply text-[20px] md:text-[25px] font-medium text-start text-secondary \n}\n\n.descrption{\n    @apply text-center text-xl text-[25px]  leading-8 my-2\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main_section": `QuestionAnswerCard_main_section__97l7c`,
	"titile": `QuestionAnswerCard_titile__X5qhk`,
	"descrption": `QuestionAnswerCard_descrption__wsW59`
};
export default ___CSS_LOADER_EXPORT___;
