// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserCard_mainContainer__WVN0R {

    position: relative;

    height: 200px;

    border-radius: 35px;

    border-top-right-radius: 0px;

    --tw-bg-opacity: 1;

    background-color: rgb(255 255 255 / var(--tw-bg-opacity));

    --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);

    --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.UserCard_dotsImageContainer__zN1K2 {

    position: absolute;

    right: 1rem;

    top: 1rem;

    cursor: pointer
}

.UserCard_dotsImage__OSLrb {

    height: 1.25rem
}

.UserCard_dataDiv__GHhmR {

    display: flex;

    height: 100%;

    width: 100%;

    cursor: pointer;

    align-items: center;

    justify-content: center
}

.UserCard_profileImageWrapper__VCYPy {

    display: flex;

    align-items: center;

    justify-content: center
}

.UserCard_profileImage__krWas {

    height: 5rem;

    width: 5rem;

    border-radius: 9999px
}

.UserCard_userNameText__n-x8q {

    margin-top: 0.75rem;

    text-align: center;

    font-family: Inter, sans-serif;

    font-size: 1rem;

    line-height: 1.5rem;

    font-weight: 600
}
`, "",{"version":3,"sources":["webpack://./src/components/home/user-card/UserCard.module.css"],"names":[],"mappings":"AACE;;IAAA,kBAMiB;;IANjB,aAMiB;;IANjB,mBAMiB;;IANjB,4BAMiB;;IANjB,kBAMiB;;IANjB,yDAMiB;;IANjB,gDAMiB;;IANjB,6DAMiB;;IANjB;AAMiB;;AAIjB;;IAAA,kBAA8C;;IAA9C,WAA8C;;IAA9C,SAA8C;;IAA9C;AAA8C;;AAI9C;;IAAA;AAAU;;AAIV;;IAAA,aAAsE;;IAAtE,YAAsE;;IAAtE,WAAsE;;IAAtE,eAAsE;;IAAtE,mBAAsE;;IAAtE;AAAsE;;AAItE;;IAAA,aAAuC;;IAAvC,mBAAuC;;IAAvC;AAAuC;;AAIvC;;IAAA,YAA8B;;IAA9B,WAA8B;;IAA9B;AAA8B;;AAI9B;;IAAA,mBAAyD;;IAAzD,kBAAyD;;IAAzD,8BAAyD;;IAAzD,eAAyD;;IAAzD,mBAAyD;;IAAzD;AAAyD","sourcesContent":[".mainContainer {\n  @apply relative \n    bg-white\n     /* w-[270px] */\n    h-[200px] \n    rounded-[35px]\n    shadow-2xl\n    rounded-tr-none;\n}\n\n.dotsImageContainer {\n  @apply absolute right-4 top-4  cursor-pointer ;\n}\n\n.dotsImage {\n  @apply h-5;\n}\n\n.dataDiv {\n  @apply flex items-center justify-center h-full w-full  cursor-pointer ;\n}\n\n.profileImageWrapper {\n  @apply flex justify-center items-center;\n}\n\n.profileImage {\n  @apply h-20 w-20  rounded-full;\n}\n\n.userNameText {\n  @apply font-sans text-base mt-3 font-semibold text-center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContainer": `UserCard_mainContainer__WVN0R`,
	"dotsImageContainer": `UserCard_dotsImageContainer__zN1K2`,
	"dotsImage": `UserCard_dotsImage__OSLrb`,
	"dataDiv": `UserCard_dataDiv__GHhmR`,
	"profileImageWrapper": `UserCard_profileImageWrapper__VCYPy`,
	"profileImage": `UserCard_profileImage__krWas`,
	"userNameText": `UserCard_userNameText__n-x8q`
};
export default ___CSS_LOADER_EXPORT___;
