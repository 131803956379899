// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QuestionsList_card__3FKk4 {
  box-shadow: 10px 7px 15px rgba(194, 223, 250, 0.5);
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  width: 100%;
  border-radius: 1.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(239 241 244 / var(--tw-bg-opacity));
}@media (min-width: 768px) {.QuestionsList_card__3FKk4 {
    width: 40%;
  }
}@media (min-width: 1024px) {.QuestionsList_card__3FKk4 {
    width: 25%;
  }
}@media (min-width: 1280px) {.QuestionsList_card__3FKk4 {
    width: 20%;
    padding: 0px;
  }
}

.QuestionsList_card_header__RFZdX > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.QuestionsList_card_header__RFZdX {
  padding: 0.75rem;
}

@media (min-width: 640px) {.QuestionsList_card_header__RFZdX {
    padding: 2rem;
  }
}

@media (min-width: 768px) {.QuestionsList_card_header__RFZdX > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }
}

.QuestionsList_card_name__x1IVx {
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: -0.025em;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

@media (min-width: 768px) {.QuestionsList_card_name__x1IVx {
    font-size: 36px;
  }
}

.QuestionsList_card_title__nwTeU {
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: -0.025em;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

@media (min-width: 768px) {.QuestionsList_card_title__nwTeU {
    font-size: 24px;
  }
}

.QuestionsList_card_descrption__ELXpU {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.025em;
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

@media (min-width: 768px) {.QuestionsList_card_descrption__ELXpU {
    font-size: 16px;
  }
}

.QuestionsList_card_welcome_title_div__rGZBw {
  display: flex;
  justify-content: center;
}

.QuestionsList_image_div__PVcfM {
  display: flex;
  justify-content: center;
}

.QuestionsList_user_image__UWE7L {
  height: 180px;
  width: 180px;
  border-radius: 9999px;
}

.QuestionsList_main_title__pWAZy {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/Questions/QuestionsList.module.css"],"names":[],"mappings":"AAAA;EACE,kDAAkD;EAClD,mBAAwF;EAAxF,sBAAwF;EAAxF,WAAwF;EAAxF,qBAAwF;EAAxF,kBAAwF;EAAxF,yDAAwF;AAC1F,CADE,2BAAA;IAAA;EAAwF;AAAA,CAAxF,4BAAA;IAAA;EAAwF;AAAA,CAAxF,4BAAA;IAAA,UAAwF;IAAxF;EAAwF;AAAA;;AAIxF;EAAA,uBAAyC;EAAzC,8DAAyC;EAAzC;AAAyC;;AAAzC;EAAA;AAAyC;;AAAzC,2BAAA;IAAA;EAAyC;AAAA;;AAAzC,2BAAA;IAAA,uBAAyC;IAAzC,+DAAyC;IAAzC;EAAyC;AAAA;;AAIzC;EAAA,kBAAmG;EAAnG,kBAAmG;EAAnG,oBAAmG;EAAnG,gBAAmG;EAAnG,iBAAmG;EAAnG,wBAAmG;EAAnG,oBAAmG;EAAnG;AAAmG;;AAAnG,2BAAA;IAAA;EAAmG;AAAA;;AAInG;EAAA,kBAAmG;EAAnG,kBAAmG;EAAnG,oBAAmG;EAAnG,gBAAmG;EAAnG,iBAAmG;EAAnG,wBAAmG;EAAnG,oBAAmG;EAAnG;AAAmG;;AAAnG,2BAAA;IAAA;EAAmG;AAAA;;AAInG;EAAA,mBAAqF;EAArF,oBAAqF;EAArF,gBAAqF;EAArF,iBAAqF;EAArF,wBAAqF;EAArF,oBAAqF;EAArF;AAAqF;;AAArF,2BAAA;IAAA;EAAqF;AAAA;;AAIrF;EAAA,aAA0B;EAA1B;AAA0B;;AAI1B;EAAA,aAA0B;EAA1B;AAA0B;;AAI1B;EAAA,aAAuC;EAAvC,YAAuC;EAAvC;AAAuC;;AAIvC;EAAA;AAAiB","sourcesContent":[".card {\n  box-shadow: 10px 7px 15px rgba(194, 223, 250, 0.5);\n  @apply bg-[#eff1f4] rounded-3xl w-[100%] md:w-[40%] lg:w-[25%] xl:w-[20%] my-5   xl:p-0 ;\n}\n\n.card_header {\n  @apply p-3 space-y-2 md:space-y-3  sm:p-8;\n}\n\n.card_name {\n  @apply text-xl text-center  leading-tight tracking-tight text-gray-900 md:text-[36px] font-semibold;\n}\n\n.card_title {\n  @apply text-xl text-center  leading-tight tracking-tight text-gray-900 md:text-[24px] font-semibold;\n}\n\n.card_descrption {\n  @apply text-sm  leading-tight tracking-tight text-gray-900 md:text-[16px] font-medium;\n}\n\n.card_welcome_title_div {\n  @apply flex justify-center;\n}\n\n.image_div {\n  @apply flex justify-center;\n}\n\n.user_image {\n  @apply rounded-full w-[180px] h-[180px];\n}\n\n.main_title{\n  @apply text-center\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `QuestionsList_card__3FKk4`,
	"card_header": `QuestionsList_card_header__RFZdX`,
	"card_name": `QuestionsList_card_name__x1IVx`,
	"card_title": `QuestionsList_card_title__nwTeU`,
	"card_descrption": `QuestionsList_card_descrption__ELXpU`,
	"card_welcome_title_div": `QuestionsList_card_welcome_title_div__rGZBw`,
	"image_div": `QuestionsList_image_div__PVcfM`,
	"user_image": `QuestionsList_user_image__UWE7L`,
	"main_title": `QuestionsList_main_title__pWAZy`
};
export default ___CSS_LOADER_EXPORT___;
