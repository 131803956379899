// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/images/backgroundimage.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Participantpage_section__4PPg1 {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-size: cover;
    background-position: center;
  }
  
  .Participantpage_container__M\\+l67 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    padding-top: 1.25rem;
}
  
  @media (min-width: 768px) {
  
    .Participantpage_container__M\\+l67 {
        margin-left: 7rem;
        margin-right: 7rem;
        padding-top: 2.5rem;
    }
}
  
  .Participantpage_header__JxP17 {
    display: flex;
    justify-content: space-between;
}
  
  .Participantpage_WelcomeText__QpVkr {
    display: flex;
    align-items: center;
    gap: 1rem;
    text-align: left;
    font-family: Amiri-Quran;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.38px;
}
  
  .Participantpage_nameText__tiF2g {
    margin-top: 0.75rem;
    margin-left: 2.5rem;
    font-family: Amiri-Quran;
    font-size: 36px;
    font-weight: 400;
    line-height: 24px;
}
  
  .Participantpage_locationImage__QHlKI {
    height: 1.25rem;
}
  
  @media (min-width: 768px) {
  
    .Participantpage_locationImage__QHlKI {
        height: 2.5rem;
    }
}
  .Participantpage_currentparticipant__OSRFW {
    display: flex;
    justify-content: center;
}
  `, "",{"version":3,"sources":["webpack://./src/pages/judge/currentparticipant/Participantpage.module.css"],"names":[],"mappings":"AAAA;IACI,yDAA2D;IAC3D,sBAA0B;IAA1B,2BAA0B;EAC5B;;EAGE;IAAA,oBAAmC;IAAnC,qBAAmC;IAAnC;AAAmC;;EAAnC;;IAAA;QAAA,iBAAmC;QAAnC,kBAAmC;QAAnC;IAAmC;AAAA;;EAInC;IAAA,aAA2B;IAA3B;AAA2B;;EAI3B;IAAA,aAK6B;IAL7B,mBAK6B;IAL7B,SAK6B;IAL7B,gBAK6B;IAL7B,wBAK6B;IAL7B,iBAK6B;IAL7B,iBAK6B;IAL7B,gBAK6B;IAL7B,iBAK6B;IAL7B;AAK6B;;EAI7B;IAAA,mBAKe;IALf,mBAKe;IALf,wBAKe;IALf,eAKe;IALf,gBAKe;IALf;AAKe;;EAIf;IAAA;AAAkB;;EAAlB;;IAAA;QAAA;IAAkB;AAAA;EAGlB;IAAA,aAAyB;IAAzB;AAAyB","sourcesContent":[".section {\n    background-image: url(\"/public/images/backgroundimage.png\");\n    @apply bg-cover bg-center ;\n  }\n  \n  .container {\n    @apply  mx-5 pt-5 md:mx-28 md:pt-10;\n  }\n  \n  .header {\n    @apply flex justify-between;\n  }\n  \n  .WelcomeText {\n    @apply flex \n    font-[Amiri-Quran] \n    text-2xl font-normal \n    leading-[24px]\n     tracking-[0.38px] \n     text-left items-center gap-4;\n  }\n  \n  .nameText {\n    @apply mt-3\n     ml-10\n    font-[Amiri-Quran] \n     text-[36px] \n     font-normal \n     leading-[24px];\n  }\n  \n  .locationImage {\n    @apply h-5 md:h-10;\n  }\n  .currentparticipant{\n    @apply flex justify-center \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `Participantpage_section__4PPg1`,
	"container": `Participantpage_container__M+l67`,
	"header": `Participantpage_header__JxP17`,
	"WelcomeText": `Participantpage_WelcomeText__QpVkr`,
	"nameText": `Participantpage_nameText__tiF2g`,
	"locationImage": `Participantpage_locationImage__QHlKI`,
	"currentparticipant": `Participantpage_currentparticipant__OSRFW`
};
export default ___CSS_LOADER_EXPORT___;
